import React from "react"
import SEO from "../components/SEO"
import imageMapStyles from "./index.module.css"
import { graphql } from "gatsby"
import Navigation from "../components/Navigation"
import i18nHOC from "../components/i18nHOC"

const originalBackgroundSize = { width: 1620, height: 1080 }

class Layer extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      active: false
    }
  }

  setActive(active) {
    this.setState({
      active
    })
    this.props.onActiveChange(active)
  }

  render() {
    let { images, pageUrl, linkText, layerZIndex, scale } = this.props
    const imagesArray = Array.isArray(images) ? images : [images]

    return <div className={`${imageMapStyles.layer} ${this.state.active ? imageMapStyles.active : ''}`}>
      {
        imagesArray.map((image, imageIdx) => {
          const scaleTransform = "scale(" + scale + ")"
          const translateTransform = "translate(" + scale * image.left * 0.3125 + "px, " + scale * image.top * 0.3125 + "px)"

          return <a href={pageUrl} title={linkText}>
            <img src={image.file.childImageSharp.original.src} alt=""
                 style={{
                   position: "absolute",
                   zIndex: (layerZIndex + imageIdx * 10).toString(),
                   transformOrigin: "top left",
                   transform: translateTransform + " " + scaleTransform
                 }}
                 onMouseEnter={() => this.setActive(true)}
                 onMouseLeave={() => this.setActive(false)}
                 onTouchStart={() => this.setActive(true)}
                 onTouchEnd={() => this.setActive(false)} />
          </a>
        })
      }
    </div>
  }
}

class ImageMap extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      windowSize: {
        width: typeof window === 'undefined' ? 0 : window.width,
        height: typeof window === 'undefined' ? 0 : window.height,
      },
      activeText: ''
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("load", this.updateWindowDimensions);

    const urlNoLang = document.URL.endsWith('en/') ? document.URL.replace('en/', '') : document.URL

    if (urlNoLang.endsWith("pasmurr.com/") || urlNoLang.endsWith(":9000/") || urlNoLang.endsWith(":8000/")) {
      document.body.style.overflowY = "hidden";
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }})
  }

  render() {
    const windowSize = this.state.windowSize
    const fittedHeight = originalBackgroundSize.height * windowSize.width / originalBackgroundSize.width
    const verticalOffset = (fittedHeight - windowSize.height) / 2

    const layersData = [
      {
        images: [{ file: this.props.data.cherryPlum, top: 2017, left: 4011 }],
        linkText: this.props.t("items.cherryPlum.title"),
        pageUrl: "/cherry-plum"
      },
      {
        images: [{ file: this.props.data.nettle, top: 1912, left: 3655 }],
        linkText: this.props.t("items.nettle.title"),
        pageUrl: "/nettle"
      },
      {
        images: [{ file: this.props.data.atriplex, top: 1929, left: 1224 }],
        linkText: this.props.t("items.saltbush.title"),
        pageUrl: "/saltbush"
      },
      {
        images: [{ file: this.props.data.arctium, top: 1667, left: 2244 }],
        linkText: this.props.t("items.burdock.title"),
        pageUrl: "/burdock"
      },
      {
        images: [{ file: this.props.data.oregano, top: 2021, left: 765 }],
        linkText: this.props.t("items.oregano.title"),
        pageUrl: "/oregano"
      },
      {
        images: [{ file: this.props.data.dandelion, top: 1984, left: 3288 }],
        linkText: this.props.t("items.dandelion.title"),
        pageUrl: "/dandelion"
      },
      {
        images: [{ file: this.props.data.barbareaAndArugula, top: 2021, left: 4388 }],
        linkText: this.props.t("items.arugulaAndWintercress.title"),
        pageUrl: "/arugula-and-wintercress"
      },
      {
        images: [{ file: this.props.data.driedMintAndJuniper, top: 2389, left: 2319 }],
        linkText: this.props.t("items.mintAndJuniper.title"),
        pageUrl: "/mint-and-juniper"
      },
      {
        images: [{ file: this.props.data.mulberryMarmalade, top: 1997, left: 1722 }],
        linkText: this.props.t("items.mulberry.title"),
        pageUrl: "/mulberry"
      },
      {
        images: [{ file: this.props.data.whiteAcaciaFlowers1, top: 1967, left: 2689 },
          { file: this.props.data.whiteAcaciaFlowers2, top: 2072, left: 306 }],
        linkText: this.props.t("items.blackLocust.title"),
        pageUrl: "/black-locust"
      }
    ]

    const windowAspectRatio = windowSize.width / windowSize.height
    const backgroundAspectRatio = originalBackgroundSize.width / originalBackgroundSize.height


    return (
      <div className={imageMapStyles.imageMap}>
        <SEO title={this.props.t('homepageTitle')}/>
        <div className={imageMapStyles.content}>
          <div className={imageMapStyles.headerWrapper}>
            <Navigation hideHomeLink={true}/>
          </div>
          <p style={{textAlign: 'center', marginTop: '10%'}}>{this.state.activeText}</p>
        </div>
        <div className={imageMapStyles.layersContainer}
             style={{ top: Math.min(-verticalOffset, 0) }}>
          {
            layersData.map((layer, idx) => <Layer images={layer.images}
                                                  onActiveChange={isActive => this.setState({ activeText: isActive ? layer.linkText : '' })}
                                                  linkText={layer.linkText}
                                                  pageUrl={layer.pageUrl}
                                                  scale={windowAspectRatio > backgroundAspectRatio ? windowSize.width / originalBackgroundSize.width : windowSize.height / originalBackgroundSize.height}
                                                  layerZIndex={3 + idx}
                                                  verticalOffset={verticalOffset} />)
          }
          <img src={this.props.data.background.childImageSharp.original.src} alt=""
               className={imageMapStyles.background} />
        </div>
      </div>
    )
  }
}

export default i18nHOC(ImageMap);

export const query = graphql`
  query {
    background: file(relativePath: {eq: "image-map/Background.jpg"}) {
    ...OriginalImage
    }
    cherryPlum: file(relativePath: {eq: "image-map/Варенье из алычи.png"}) {
    ...OriginalImage
    }
    nettle: file(relativePath: {eq: "image-map/Крапива.png"}) {
    ...OriginalImage
    }
    atriplex: file(relativePath: {eq: "image-map/Лебеда.png"}) {
    ...OriginalImage
    }
    arctium: file(relativePath: {eq: "image-map/Маринованный лопух.png"}) {
    ...OriginalImage
    }
    oregano: file(relativePath: {eq: "image-map/Сироп душицы.png"}) {
    ...OriginalImage
    }
    dandelion: file(relativePath: {eq: "image-map/Сироп из цветов одуванчика.png"}) {
    ...OriginalImage
    }
    barbareaAndArugula: file(relativePath: {eq: "image-map/Сурепка и руккола.png"}) {
    ...OriginalImage
    }
    driedMintAndJuniper: file(relativePath: {eq: "image-map/Сушёная мята и можжевельник.png"}) {
    ...OriginalImage
    }
    mulberryMarmalade: file(relativePath: {eq: "image-map/Тутовый мармелад.png"}) {
    ...OriginalImage
    }
    whiteAcaciaFlowers1: file(relativePath: {eq: "image-map/Цветы белой акации 1.png"}) {
    ...OriginalImage
    }
    whiteAcaciaFlowers2: file(relativePath: {eq: "image-map/Цветы белой акации 2.png"}) {
    ...OriginalImage
    }
  }
  
  fragment OriginalImage on File {
    childImageSharp {
      original {
        src
      }
    }
  }
`

